











































import Vue, { VueConstructor } from 'vue';
import formSubmit from '@/mixins/formSubmit';
import { FormWrapperRequest } from '@/types/form';
import Btn from '@/components/buttons/Btn.vue';
import AppModal from '@/components/AppModal.vue';
import ApiError from '@/components/ApiError.vue';
import FieldLabel from '@/components/fields/FieldLabel.vue';
import FieldDescription from '@/components/fields/FieldDescription.vue';
import FieldSecurityLevel from '@/components/fields/FieldSecurityLevel.vue';
import { FileMetadata } from '@/util/fileIcons';
import ApiService from '@/services/api.service';
import EditBtn from '@/components/buttons/EditBtn.vue';
import { SecurityLevel } from '@/types';

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'EditFile',
  components: {
    EditBtn,
    Btn,
    AppModal,
    ApiError,
    FieldLabel,
    FieldDescription,
    FieldSecurityLevel,
  },
  mixins: [formSubmit],
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  computed: {
    projectId(): string {
      return this.$route.params.projectId;
    },
    request(): FormWrapperRequest {
      return {
        config: {
          data: this.model,
          method: 'put',
          url: `/project/${this.projectId}/file/${this.$route.params.fileId}`,
        },
      };
    },
  },
  data() {
    return {
      error: '',
      hasError: false,
      model: {
        label: '',
        description: '',
        securityLevel: SecurityLevel.None,
        version: 0,
      } as FileMetadata,
      duplicateError: false,
    };
  },
  methods: {
    async checkDuplicates() {
      if (this.file.label !== this.model.label) {
        const existing = await ApiService.put(`/project/${this.projectId}/file/names`,
          { names: [this.model.label] });
        this.duplicateError = existing.length > 0;
      }
    },
    openModal() {
      this.model.label = this.file.label;
      this.model.description = this.file.description;
      this.model.securityLevel = this.file.securityLevel;
      (this.$refs.modal as any).open();
    },
    async onSubmit() {
      this.error = '';
      await this.submit(this.request, this.$refs.form);
      if (this.hasError) {
        return;
      }
      (this.$refs.modal as any).close();
      this.model = { label: '', securityLevel: SecurityLevel.None, version: 0 };

      this.notify('File metadata updated successfully');
      this.$emit('submit');
    },
  },

});
