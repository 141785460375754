















































import Vue from 'vue';
import AppCard from '@/components/card/AppCard.vue';
import Btn from '@/components/buttons/Btn.vue';

export default Vue.extend({
  name: 'FilePreview',
  components: {
    Btn,
    AppCard,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$q.fullscreen.isActive': function (val) {
      // without this, pressing the esc key will produce unexpected behaviour
      if (!val) {
        this.$q.fullscreen.exit();
      }
    },
  },
  computed: {
    minHeight(): string {
      return this.$q.fullscreen.isActive ? '90vh' : '50vh';
    },
    hasPreview(): boolean {
      // @TODO - ideally we will have preview for other file types
      return this.isPdf || this.isImage || this.isOffice;
    },
    isPdf(): boolean {
      return this.isType('pdf');
    },
    isImage(): boolean {
      return this.isType('image');
    },
    isOffice(): boolean {
      return this.isExt('doc') || this.isExt('ppt') || this.isExt('xls');
    },
    fileUrl(): string {
      return document.location.origin + this.file.path;
    },
  },
  methods: {
    isExt(fileExt: string): boolean {
      if (!this.file.format) {
        return false;
      }
      return this.file.format.includes(fileExt);
    },
    isType(fileType: string): boolean {
      if (!this.file.type) {
        return false;
      }
      return this.file.type.includes(fileType);
    },
    async toggleFullScreen() {
      const el = document.querySelector('.file-preview');

      if (el) {
        await this.$q.fullscreen.toggle(el);
      }
    },
  },
});
