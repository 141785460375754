import { SecurityLevel } from '@/types';

export interface FileMetadata {
  label: string;
  id?: string;
  formId?: string,
  modellingStateId?: string,
  description?: string;
  format?: string;
  securityLevel?: SecurityLevel;
  version?: number,
}

export const allowedExtensions = [
  '3ds',
  'dwg',
  'max',
  'cert',
  'log',
  'pem',
  'srt',
  'msg',
  'tif',
  'tiff',
  'odp',
  'rtf',
  'tex',
  'txt',
  'pdf',
  'docx',
  'pages',
  'doc',
  'xls',
  'xlt',
  'xlsx',
  'xlx',
  'numbers',
  'ppt',
  'pptx',
  'pps',
  'key',
  'aif',
  'aiff',
  'wma',
  'mp3',
  'wav',
  'mid',
  'dxf',
  'mp4',
  'wmv',
  'avi',
  'mov',
  'flv',
  'zip',
  'iso',
  'jpeg',
  'jpg',
  'png',
  'svg',
  'gif',
  'bmp',
  'mdb',
  'sql',
  'csv',
  'xml',
  'dbf',
  'db',
  'html',
  'htm',
  'json',
];

export const isValidExtension = (fileName: string) => {
  if (!fileName) {
    return false;
  }

  const ext = fileName.split('.').pop();
  if (!ext) {
    return false;
  }
  return allowedExtensions.includes(ext);
};

export function getType(fileName: string): string {
  let type = 'file';
  if (!fileName) {
    return type;
  }
  const ext = fileName.split('.').pop();

  switch (ext) {
    case 'pdf':
      type = 'pdf';
      break;
    case 'pages':
    case 'docx':
    case 'doc':
      type = 'word';
      break;
    case 'rtf':
    case 'tex':
    case 'txt':
      type = 'text';
      break;
    case 'xls':
    case 'xlt':
    case 'xlx':
    case 'xlsx':
    case 'numbers':
      type = 'excel';
      break;
    case 'ppt':
    case 'pptx':
    case 'pps':
    case 'key':
      type = 'powerpoint';
      break;
    case 'aif':
    case 'aiff':
    case 'wma':
    case 'mp3':
    case 'wav':
    case 'mid':
    case 'flac':
    case 'dxf':
      type = 'audio';
      break;
    case 'mp4':
    case 'wmv':
    case 'avi':
    case 'mov':
    case 'flv':
      type = 'video';
      break;
    case 'zip':
    case 'iso':
      type = 'archive';
      break;
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'svg':
    case 'gif':
    case 'bmp':
      type = 'image';
      break;
    case 'mdb':
    case 'sql':
    case 'csv':
    case 'xml':
    case 'dbf':
    case 'db':
    case 'json':
      type = 'database';
      break;
    case 'html':
    case 'htm':
      type = 'html';
      break;
    case 'msg':
      type = 'mail';
      break;
    default:
      type = 'file';
  }

  return type;
}

const map = {
  pdf: {
    hasPreview: true,
    icon: 'fas fa-file-pdf',
  },
  word: {
    hasPreview: false,
    icon: 'fas fa-file-word',
  },
  excel: {
    hasPreview: false,
    icon: 'fas fa-file-excel',
  },
  powerpoint: {
    hasPreview: false,
    icon: 'fas fa-file-powerpoint',
  },
  audio: {
    hasPreview: true,
    icon: 'fas fa-file-audio',
  },
  video: {
    hasPreview: true,
    icon: 'fas fa-file-video',
  },
  database: {
    hasPreview: false,
    icon: 'fas fa-database',
  },
  archive: {
    hasPreview: false,
    icon: 'fas fa-file-archive',
  },
  image: {
    hasPreview: true,
    icon: 'fas fa-file-image',
  },
  html: {
    hasPreview: false,
    icon: 'fas fa-file-code',
  },
  file: {
    hasPreview: false,
    icon: 'fas fa-file',
  },
  mail: {
    hasPreview: false,
    icon: 'fas fa-envelope',
  },
  text: {
    hasPreview: false,
    icon: 'fas fa-file-alt',
  },
};

export function getFileTypeDetails(fileName: string): any {
  return (map as any)[getType(fileName)];
}
