import { render, staticRenderFns } from "./ViewFile.vue?vue&type=template&id=4d58c58b&"
import script from "./ViewFile.vue?vue&type=script&lang=ts&"
export * from "./ViewFile.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QIcon,QSpace,QTooltip,QTd});
