
























































































































































import Vue, { VueConstructor } from 'vue';
import formSubmit from '@/mixins/formSubmit';
import { FormWrapperRequest } from '@/types/form';
import Btn from '@/components/buttons/Btn.vue';
import AppModal from '@/components/AppModal.vue';
import ApiError from '@/components/ApiError.vue';
import FieldLabel from '@/components/fields/FieldLabel.vue';
import FieldDescription from '@/components/fields/FieldDescription.vue';
import FieldSecurityLevel from '@/components/fields/FieldSecurityLevel.vue';
import { uploadFile } from '@/util/upload';
import { allowedExtensions, FileMetadata, getFileTypeDetails } from '@/util/fileIcons';
import ApiService from '@/services/api.service';
import { SecurityLevel } from '@/types';

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'UploadFiles',
  components: {
    Btn,
    AppModal,
    ApiError,
    FieldLabel,
    FieldDescription,
    FieldSecurityLevel,
  },
  mixins: [formSubmit],
  props: {
    isEditForm: Boolean,
  },
  computed: {
    title(): string {
      return this.isEditForm ? 'Upload new globalSettingsVersion' : 'Upload files';
    },
    uploadLabel(): string {
      if (this.isEditForm) {
        return 'Click or drag a file to this area to upload a new globalSettingsVersion';
      }
      return 'Click or drag files to this area to upload';
    },
    projectId(): string {
      return this.$route.params.projectId;
    },
    request(): FormWrapperRequest {
      let callback;
      if (this.isEditForm) {
        callback = async () => {
          await uploadFile(
            'files',
            'put',
            this.files[0],
            null,
            `/project/${this.projectId}/file/${this.$route.params.fileId}/version`,
            (event: any) => {
              this.uploadProgress[0] = event.loaded / event.total;
              this.$forceUpdate();
            },
          );
        };
      } else {
        callback = async () => {
          const files = Array.from(this.files);

          for (let i = 0; i < files.length; i += 1) {
            // eslint-disable-next-line no-await-in-loop
            await uploadFile(
              'files',
              'post',
              this.files[i],
              this.model[i],
              `/project/${this.projectId}/file`,
              (event: any) => {
                this.uploadProgress[i] = event.loaded / event.total;
                this.$forceUpdate();
              },
            );
          }
        };
      }

      return { callback };
    },
    // 20 MBs
    maxFileSizeInBytes() {
      return 20 * 1e+6;
    },
    acceptExtensions(): string {
      return this.allowedExtensions.map((t: string) => `.${t}`).join((', '));
    },
  },
  data() {
    return {
      allowedExtensions,
      files: [] as File[],
      error: '',
      hasError: false,
      model: [] as FileMetadata[],
      uploadProgress: {} as Record<string, number>,
    };
  },
  methods: {
    clearAll() {
      this.error = '';
      this.hasError = false;
      this.files = [];
      this.model = [];
      this.uploadProgress = {};
    },
    open() {
      this.clearAll();
      (this.$refs.modal as any).open();
    },
    fileSizeInMb(size: number): string {
      return `${(size / (1024 * 1024)).toFixed(2)}Mb`;
    },
    fileName(name: string): string {
      return name.substring(0, name.lastIndexOf('.'));
    },
    fileExt(name: string): string {
      return name.substring(name.lastIndexOf('.') + 1);
    },
    removeFile(index: number) {
      this.files.splice(index, 1);
    },
    async updateDataList() {
      if (this.isEditForm) {
        this.files = [this.files[0]];
        this.uploadProgress[0] = 0;
        return;
      }

      this.model = [];
      if (this.files) {
        this.files.forEach((file, index) => {
          this.model.push(
            {
              label: this.fileName(file.name),
              securityLevel: SecurityLevel.None,
              format: this.fileExt(file.name),
              version: 0,
            },
          );
          this.uploadProgress[index] = 0;
        });
        const names = this.files.map((f) => this.fileName(f.name));
        const existingFiles = await ApiService.put(`/project/${this.projectId}/file/names`, { names });
        this.model.forEach((m) => {
          const existing = existingFiles
            .find((e: FileMetadata) => e.label === m.label && e.format === m.format);
          if (existing) {
            m.version = existing.version + 1;
            m.id = existing.id;
          }
        });
      }
    },
    getFileDetails(fileName: string): any {
      return getFileTypeDetails(fileName);
    },
    async onSubmit() {
      if (this.files.length === 0) {
        this.error = `Please add ${this.isEditForm ? 'a file' : 'at least one file'}`;
        return;
      }
      this.error = '';

      await this.submit(this.request, this.$refs.form);

      if (this.hasError) {
        return;
      }
      (this.$refs.modal as any).close();
      this.clearAll();

      this.notify('Uploaded successfully');
      this.$emit('submit');
    },
  },

});
