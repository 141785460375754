




































// library
import Vue, { VueConstructor } from 'vue';
import formSubmit from '@/mixins/formSubmit';
// components
import Btn from '@/components/buttons/Btn.vue';
import AppModal from '@/components/AppModal.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'RemoveResourceFromFile',
  components: {
    Btn,
    AppModal,
  },
  props: {
    many: Boolean,
    isFile: Boolean,
    count: Number,
  },
  computed: {
    getText() {
      if (this.isFile) {
        return `Are you sure you want to unlink ${this.many ? 'ALL files' : 'this file'}?`;
      }
      return `Are you sure you want to unlink ${this.many ? 'All items' : 'this item'} from the file?`;
    },
  },
});
