



































import Vue from 'vue';
import ApiService from '@/services/api.service';
import notify from '@/util/notify';
import List from '@/components/list/List.vue';
import DescriptionCol from '@/components/list/DescriptionCol.vue';
import RemoveResourceFromFile from '@/views/projects/files/resources/RemoveResourceFromFile.vue';
import LayerBadge from '@/components/LayerBadge.vue';
import { RequestConfig, TableColumn } from '@/types/list';
import AddResourcesToFile from '@/views/projects/files/resources/AddResourcesToFile.vue';
import { ResourceType } from '@/types/resource';

export default Vue.extend({
  name: 'ListResourcesForFile',
  components: {
    LayerBadge,
    List,
    DescriptionCol,
    AddResourcesToFile,
    RemoveResourceFromFile,
  },
  props: {
    fileId: {
      type: String,
      required: true,
    },
    params: {
      type: [Object, Function],
      required: false,
    },
  },
  computed: {
    requestConfig(): RequestConfig {
      return {
        url: `project/${this.projectId}/assets/info?fileIds=${this.fileId}`,
      };
    },
    projectId(): string {
      return this.$route.params.projectId;
    },
    extraColumns(): TableColumn[] {
      return [
        {
          name: 'description',
          label: 'Description',
          field: 'description',
          align: 'left',
          sortable: false,
        },
        {
          name: 'layer',
          label: 'Layer',
          field: 'layer',
          align: 'center',
          sortable: false,
        },
        {
          name: 'type',
          label: 'Item Type',
          field: 'type',
          align: 'center',
          sortable: true,
        },
      ];
    },
  },
  methods: {
    isAssetResource(resourceType: ResourceType): boolean {
      return resourceType === ResourceType.ASSET;
    },
    async goToResource(event: any) {
      const { orgId, projectId } = this.$route.params;
      await this.$router.push(`/org/${orgId}/project/${projectId}/${event.type}/${event.id}`);
    },
    async removeResourceFromFile(resources: any[], loadRows: () => void) {
      try {
        const assetIds = resources.map((r) => r.id);
        await ApiService.put(`project/${this.projectId}/file/${this.fileId}/unlink`, { assetIds });
        notify.success('Resource(s) have been unlinked from this file');
        await loadRows();
        this.$emit('change');
      } catch (e: any) {
        notify.danger('The resource(s) could not be unlinked.');
      }
    },
    async onAddResource(loadRows: () => void) {
      await loadRows();
      this.$emit('change');
    },
  },
});
