import { render, staticRenderFns } from "./UploadFiles.vue?vue&type=template&id=72d5a2d2&"
import script from "./UploadFiles.vue?vue&type=script&lang=ts&"
export * from "./UploadFiles.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QForm from 'quasar/src/components/form/QForm.js';
import QFile from 'quasar/src/components/file/QFile.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QForm,QFile,QIcon,QItem,QItemSection,QLinearProgress,QExpansionItem,QTooltip,QSpace});
