








































import Vue from 'vue';
import AppModal from '@/components/AppModal.vue';
import DescriptionCol from '@/components/list/DescriptionCol.vue';
import List from '@/components/list/List.vue';
import LayerBadge from '@/components/LayerBadge.vue';
import { RequestConfig, TableColumn } from '@/types/list';
import Btn from '@/components/buttons/Btn.vue';
import ApiService from '@/services/api.service';
import notify from '@/util/notify';
import { ResourceType } from '@/types/resource';

export default Vue.extend({
  name: 'AddResourcesToFile',
  components: {
    LayerBadge,
    List,
    DescriptionCol,
    Btn,
    AppModal,
  },
  props: {
    fileId: {
      type: String,
      required: true,
    },
    params: {
      type: [Object, Function],
      required: false,
    },
  },
  computed: {
    requestConfig(): RequestConfig {
      return {
        url: `project/${this.projectId}/assets/info?notFileIds=${this.fileId}`,
      };
    },
    orgId(): string {
      return this.$route.params.orgId;
    },
    projectId(): string {
      return this.$route.params.projectId;
    },
    extraColumns(): TableColumn[] {
      return [
        {
          name: 'description',
          label: 'Description',
          field: 'description',
          align: 'left',
          sortable: false,
        },
        {
          name: 'layer',
          label: 'Layer',
          field: 'layer',
          align: 'center',
          sortable: false,
        },
        {
          name: 'type',
          label: 'Item Type',
          field: 'type',
          align: 'center',
          sortable: true,
        },
      ];
    },
  },
  methods: {
    isAssetResource(resourceType: ResourceType): boolean {
      return resourceType === ResourceType.ASSET;
    },
    async addResourcesToFile(resource: any, loadRows: () => void) {
      try {
        await ApiService.put(`project/${this.projectId}/file/${this.fileId}/link`, { assetIds: [resource.id] });
        notify.success(`${resource.label} has been linked to this file`);
        await loadRows();
        await this.$emit('changed');
      } catch (e: any) {
        notify.danger('The resource could not be linked.');
      }
    },
  },
});
