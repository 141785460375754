














































































import Vue from 'vue';
import { OrgModel } from '@/types/org';
import { ProjectModel } from '@/types/project';
import Page from '@/layouts/Page.vue';
import AppCard from '@/components/card/AppCard.vue';
import EditFile from '@/views/projects/files/EditFile.vue';
import List from '@/components/list/List.vue';
import { getFileTypeDetails } from '@/util/fileIcons';
import { RequestConfig, SearchResponse } from '@/types/list';
import DeleteModal from '@/components/DeleteModal.vue';
import FilePreview from '@/views/projects/files/FilePreview.vue';
import SecurityLevelCol from '@/components/list/SecurityLevelCol.vue';
import UploadFiles from '@/views/projects/files/UploadFiles.vue';
import ListResourcesForFile from '@/views/projects/files/resources/ListResourcesForFile.vue';

export default Vue.extend({
  name: 'ViewFile',
  components: {
    ListResourcesForFile,
    UploadFiles,
    SecurityLevelCol,
    FilePreview,
    Page,
    List,
    AppCard,
    DeleteModal,
    EditFile,
  },
  computed: {
    org(): OrgModel {
      return this.$store.getters['orgModule/details'];
    },
    project(): ProjectModel {
      return this.$store.getters['projectModule/details'];
    },
    breadcrumbs(): any[] {
      return [
        {
          label: this.org.label,
          url: `/org/${this.org.id}/projects`,
        },
        {
          label: this.project.label,
          url: `/org/${this.org.id}/project/${this.project.id}/files`,
        },
        {
          label: 'Manage file',
        },
      ];
    },
  },
  data() {
    return {
      requestConfig: {} as RequestConfig,
      files: {} as any,
      selectedFile: {} as any,
      error: '',
      extraColumns: [
        {
          name: 'size',
          label: 'Size',
          field: 'size',
          align: 'center',
          sortable: true,
        },
        {
          name: 'securityLevel',
          label: 'Security Level',
          field: 'securityLevel',
          align: 'center',
          sortable: true,
        },
        {
          name: 'version',
          field: 'version',
          label: 'Version',
          align: 'center',
          sortable: false,
        },
        {
          name: 'format',
          label: 'Format',
          field: 'format',
          align: 'center',
          sortable: true,
        },
      ],
    };
  },
  methods: {
    getFileDetails(fileName: string) {
      return getFileTypeDetails(fileName);
    },
    fileSizeInMb(size: number) {
      return `${(size / (1024 * 1024)).toFixed(2)}Mb`;
    },
    selectFile({ version }: { version: number }) {
      this.selectedFile = this.files.find((f: any) => f.version === version);
    },
    setFile(res: SearchResponse) {
      this.files = res.results;
      this.selectedFile = res.results[res.results.length - 1];
    },
    async fileDeleted() {
      await this.$router.replace(`/org/${this.$route.params.orgId}/project/${this.project.id}/files`);
    },
    async loadRows() {
      const list = this.$refs.list as any;
      if (list) {
        await list.loadRows();
      }
    },
  },
  async created() {
    this.requestConfig = {
      url: `/project/${this.project.id}/files/${this.$route.params.fileId}`,
    };
  },
});
